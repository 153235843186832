import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineBell } from "react-icons/ai";
import {
  clearNotifications,
  setNotReadNotificationCount,
} from "redux/dashbbaord/dashboardSlice";
import useApiHook from "hooks/useApiHook";
import { Clock } from "iconsax-react";
import { TimeAgo } from "helper/helper";

const NotificationDropDown = () => {
  const { auth, dashboard } = useSelector((state) => state);
  const [notifications, setNotifications] = useState([]);
  const { handleApiCall, isApiLoading } = useApiHook();
  const [loading, setLoading] = useState(false);
  const [loadMore, setLoadMore] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const markNotificationsAsRead = () => {
    const updatedNotifications = notifications.map((n) => {
      if (!n.read) {
        return { ...n, read: true };
      }
      return n;
    });
    setNotifications(updatedNotifications);
    dispatch(setNotReadNotificationCount(0));

    if (!isClosed) dispatch(clearNotifications());
  };

  const handleMarkNotifications = async () => {
    markNotificationsAsRead();

    const result = await handleApiCall({
      method: "put",
      url: "/notifications/mark-all-as-read",
      token: auth?.userInfo?.accessToken,
    });

    // if (result?.status === 200) {
    // console.log('All notifications marked as read');
    // }
  };

  const handleNotifications = async (isOpen) => {
    if (isOpen) {
      if (!dashboard?.notifications?.length > 0) return;
      else dispatch(clearNotifications());

      setLoading(true);
      const result = await handleApiCall({
        method: "get",
        url: `/notifications/all?page=1&limit=10`,
        token: auth?.userInfo?.accessToken,
      });

      if (result?.status === 200) {
        setNotifications(result?.data?.notifications);
        setCount(result?.data?.count);
      }

      setLoading(false);
    }
  };

  const handleMarkOneNotification = async (id, url) => {
    navigate(url.split("&")[0]);
    setNotifications(
      notifications.map((n) => {
        if (n._id === id) {
          return { ...n, read: true };
        }
        return n;
      })
    );
    dispatch(
      setNotReadNotificationCount(dashboard?.notReadNotificationCount - 1)
    );

    const result = await handleApiCall({
      method: "put",
      url: `/notifications/mark-one-as-read/${id}`,
      token: auth?.userInfo?.accessToken,
    });

    if (result?.status === 200) {
      // console.log('Notification marked as read');
    }
  };

  const getNextNotifications = async () => {
    setLoadMore(true);

    const result = await handleApiCall({
      method: "get",
      url: `/notifications/all?page=${page + 1}&limit=${limit}`,
      token: auth?.userInfo?.accessToken,
    });

    if (result?.status === 200) {
      setNotifications([...notifications, ...result?.data?.notifications]);
      setPage(page + 1);
      dispatch(setNotReadNotificationCount(result.data?.notReadCount));
    }

    setLoadMore(false);
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      const result = await handleApiCall({
        method: "get",
        url: "/notifications/all",
        token: auth?.userInfo?.accessToken,
      });
      if (isMounted && result?.status === 200) {
        setNotifications(result?.data?.notifications);
        setCount(result?.data?.count);
        dispatch(setNotReadNotificationCount(result.data?.notReadCount));
      }
    };

    if (auth?.isLogin && auth?.userInfo?.accessToken) fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (dashboard?.notifications?.length > 0 && !isClosed) {
      const updatedNotifications = [
        dashboard.notifications[0],
        ...notifications,
      ];
      setNotifications(updatedNotifications);
    }
  }, [dashboard.notifications, isClosed]);

  return (
    <Fragment>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`justify-center items-center hidden hover:cursor-pointer lg:min-w-[70px] lg:flex-grow lg:flex h-full focus:outline-none ${
                open
                  ? "text-white bg-ezGreen hover:text-white"
                  : "text-ezBlack hover:bg-ezLightGreen bg-transparent"
              }`}
              onClick={() => {
                setIsClosed(open);
                handleNotifications(!open);
              }}
            >
              <AiOutlineBell
                className="h-5 w-5 md:h-6 md:w-6"
                aria-hidden="true"
              />
              {dashboard?.notReadNotificationCount > 0 && (
                <span className="absolute bottom-8 left-10 text-xs px-2 py-1 bg-ezGreen text-white rounded-full">
                  {dashboard?.notReadNotificationCount}
                </span>
              )}
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute md:right-0 -right-4 z-10 mt-2 mr-2 w-screen max-w-md transform px-2 sm:px-0">
                {notifications.length === 0 ? (
                  <div className="absolute right-0 z-10 mt-2 w-11/12 md:w-full max-w-sm origin-top-right rounded-3xl bg-white shadow-lg focus:outline-none overflow-hidden ">
                    <div className="flex justify-center items-center px-6 py-3 m-1 bg-ezLightWhite rounded-full">
                      No notifications yet
                    </div>
                  </div>
                ) : (
                  <div className="absolute right-0 z-10 mt-2 w-11/12 md:w-full p-3 max-w-sm md:max-w-md origin-top-right rounded-md bg-ezLightWhite shadow-lg focus:outline-none overflow-hidden ">
                    <div className="flex justify-between items-center pb-2 mx-[1px] mt-[1px] bg-ezLightWhite ">
                      <h3 className="text-base font-semibold">Notifications</h3>
                      <button
                        type="button"
                        className="text-xs text-ezGreen font-semibold focus:outline-none"
                        onClick={() => handleMarkNotifications()}
                      >
                        Mark All as Read
                      </button>
                    </div>
                    <div className="max-h-80 overflow-y-auto rounded-md relative pr-2">
                      {isApiLoading && loading ? (
                        <div className="flex items-center justify-center h-20">
                          <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-ezGreen"></div>
                        </div>
                      ) : (
                        notifications?.map((n, index) => (
                          <button
                            key={n.id}
                            className={`py-3 pl-2 pr-6 bg-white hover:bg-ezLightGreen rounded-md text-left w-full ${
                              index !== 0 ? " mt-2" : ""
                            }`}
                            onClick={() => {
                              close();
                              handleMarkOneNotification(n._id, n.url);
                            }}
                          >
                            <div className="w-full ml-2">
                              <span className="flex justify-between">
                                <p className="text-sm font-medium text-gray-900">
                                  {n.title}
                                </p>
                                <p className="flex items-center text-xs text-gray-500 mb-2">
                                  {/* <Clock
                                    className='mr-1 w-4 h-4'
                                    color='#555555'
                                  /> */}
                                  <TimeAgo date={n.createdAt} />
                                </p>
                              </span>
                              <p className="text-xs text-gray-500 w-full flex items-center justify-between self-center">
                                {n.message}
                                {n.read ? null : (
                                  <span className="h-2 w-2 bg-ezGreen rounded-full"></span>
                                )}
                              </p>
                            </div>
                          </button>
                        ))
                      )}
                      {count === notifications.length ? null : (
                        <div className="flex justify-center items-center pt-2 border-t border-gray-200">
                          {loadMore ? (
                            <div className="animate-spin rounded-full h-2 w-2 my-2 border-t-2 border-b-2 border-ezGreen"></div>
                          ) : (
                            <button
                              type="button"
                              className="text-xs text-ezGreen font-semibold focus:outline-none text-center my-1"
                              onClick={() => getNextNotifications()}
                            >
                              Load More
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </Fragment>
  );
};

export default NotificationDropDown;
